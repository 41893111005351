.mobile-head,
.m-to-top,
.m-bar,
[class^="btn_"],
.arc,
.bg-up,
.bg-sea,
.post-btns .ti.close,
.dc {
    display: none;
}

@media only screen and (max-width: 850px) {
    [class^="btn_"].ei {
        max-width: calc((100% / 8.1) - 1px)
    }

    [class^="btn_"].se {
        max-width: calc((100% / 7.1) - 1px)
    }

    [class^="btn_"].si {
        max-width: calc((100% / 6.1) - 1px)
    }

    [class^="btn_"].fi {
        max-width: calc((100% / 5.1) - 1px)
    }

    [class^="btn_"].fo {
        max-width: calc((100% / 4.1) - 1px)
    }

    [class^="btn_"].th {
        max-width: calc((100% / 3.1) - 1px)
    }

    .mobile-head {
        display: block;
    }

    .mobile-head {
        position: relative;
        margin: 0;
        width: 100%;
    }

    .desc {
        margin: 10px auto 0;
        max-height: 20vh;
        overflow-y: auto;
        cursor: auto
    }

    .mobile-head .pref {
        margin: 0 auto 10px;
        padding: 0 10px
    }

    .cover {
        width: 100%;
        max-height: 25vh;
        overflow: hidden;
        border-top: 1px solid var(--borders2);
        box-shadow: inset 0 1px 0 var(--borders1);
    }

    .cover img {
        width: 100%;
        max-height: 25vh;
        object-fit: fill;
    }

    .tasks {
        width: 100%;
        justify-content: center;
    }

    .tasks2, .start, .posts, .sea, .imej, .up, .update, .thm,.start-wrap, .image-wrap, .up-wrap, .search-wrap, .sidebar, .to-top, .post-btns .ti.ex, .tc {
        /* later check again at line 3 */
        /* this hide the date, clock and buttons for big screen */
        display: none !important;
    }

    .package, .wrapper, .error_wrapper, .search_error {
        width: 100vw;
        max-width: 100vw;
    }

    .package {
        margin: 0 auto;
        border-width: 0;
        outline: 0 solid var(--borders2);
        box-shadow: 0 0 0 rgba(50,50,50,.1);
    }

    .wrapper {
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;
    }

    .packhead {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
    }

    .tabs {
        margin: 0 auto;
        width: 100vw;
        padding: 50px 15px 70px;
        height: auto;
        border-width: 0;
        border-top: 1px solid var(--borders2);
        box-shadow: inset 0 1px 0 var(--borders1);
    }

    [class^="tab_"],.entry,.bb,.bbb,.error,.no-posts {
        width: 100%;
    }

    body.imgbg .tabs,body.imgbg .error_wrapper,body.imgbg .search_error {
        background-image: var(--bg-img);
        background-size: var(--bg-size);
        background-repeat: var(--bg-repeat);
        background-attachment: var(--bg-attach);
        background-position: var(--bg-position);
    }

    .m-to-top,[class^="btn_"],.dc {
        display: inline-flex;
    }

    /* ****************************************************** */
    [data-theme="daynight"] body.light #button_a:checked~.taskbar>.tasks>.btn_a,
    [data-theme="daynight"] body.light #button_b:checked~.taskbar>.tasks>.btn_b,
    [data-theme="daynight"] body.light #button_c:checked~.taskbar>.tasks>.btn_c,
    [data-theme="daynight"] body.light #button_c:checked~.taskbar>.tasks>.btn_d,
    [data-theme="daynight"] body.light #button_c:checked~.taskbar>.tasks>.btn_e,
    [data-theme="daynight"] body.light #button_c:checked~.taskbar>.tasks>.btn_f,
    [data-theme="daynight"] body.light #button_c:checked~.taskbar>.tasks>.btn_g {
        background: rgba(255,255,255,.12);
    }

    #button_a:checked~.taskbar>.tasks>.btn_a, 
    #button_b:checked~.taskbar>.tasks>.btn_b, 
    #button_c:checked~.taskbar>.tasks>.btn_c, 
    #button_d:checked~.taskbar>.tasks>.btn_d, 
    #button_e:checked~.taskbar>.tasks>.btn_e, 
    #button_f:checked~.taskbar>.tasks>.btn_f,
    #button_g:checked~.taskbar>.tasks>.btn_g {
        cursor: default;
        border-top: 1px solid var(--borders2);
        border-left: 1px solid var(--borders2);
        border-right: 1px solid var(--borders1);
        border-bottom: 1px solid var(--borders1);
        box-shadow: inset 0 0 1px 1px var(--borders);
    }
}