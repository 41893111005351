/* Reset and General Styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    color: var(--text);
    background-color: var(--bg);
    font-family: var(--body-font);
    font-size: var(--body-size);
    font-weight: var(--body-wgt);
    line-height: var(--line-hgt);
    overflow-x: hidden;
}

a {
    color: var(--links);
    text-decoration: none;
}

a:hover {
    color: var(--links-hover);
}

u {
    text-decoration: underline
}

b,strong {
    font-weight: 700
}

sub,small {
    font-size: calc(var(--body-size) - 2px)
}

h1,
.t-title {
    font-size: calc(var(--body-size) + 15px);
    line-height: var(--line-hgt)
}

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 10px 0
}

p {
    margin: 10px 0
}

ol, ul {
    margin: 0 0 0 3em
}

ul {
    list-style-type: square
}

ol li, ul li {
    margin: 5px 0;
}

pre {
    white-space: pre-wrap;
    overflow-x: auto;
    padding: 15px;
    font-family: monospace,sans-serif;
    background: var(--ask);
    color: var(--text);
    border-radius: 10px
}

hr {
    width: 25%;
    margin: 10px auto;
    border-top: 1px solid var(--borders)
}

.visible {
    display: flex !important; /* block !important */
    opacity: 0; /* Start with opacity 0 */
    animation: fadeIn 0.5s ease forwards; /* Use the fadeIn animation with a duration of 0.5s */
}
@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.hidden {
    opacity: 1; /* Start with opacity 1 */
    animation: fadeOut 0.5s ease forwards; /* Use the fadeOut animation with a duration of 0.5s */
}
@keyframes fadeOut {
    from {
        opacity: 1; /* Start with opacity 1 */
    }
    to {
        opacity: 0; /* End with opacity 0 */
        display: none; /* Apply display: none; after the animation completes */
    }
}

/* Background */
body.imgbg {
    /* background: var(--captions); */
    background-image: var(--bg-img);
    background-size: var(--bg-size);
    background-repeat: var(--bg-repeat);
    background-attachment: var(--bg-attach);
    background-position: var(--bg-position);
}

.dn {
    margin: 0;
    cursor: pointer; /* nurul add 20240216 */
}

body.night .diemnox {
    display: block;
}

input[type=checkbox], body.night .moon {
    display: none;
}

input[type=checkbox]:checked+.dn {
    vertical-align: middle;
}

body.light .nox,
body.dark .diem {
    display: none
}

/* Layout */
.flex {
    display: flex;
    flex-wrap: wrap;
}

.justify {
    justify-content: space-between;
}

.center {
    align-content: center;
}

.min {
    margin-left: 0;
}

.none {
    display: none;
}

.padding {
    padding: 10px;
    border-top: 1px solid var(--borders2);
    box-shadow: inset 0 1px 0 var(--borders1);
}

.container {
    margin: 0;
    width: 100%;
}

article {
    margin: 0 0 var(--post-gap);
    width: 100%;
    background: var(--posts);
    border: 1px solid var(--borders1);
    outline: 1px solid var(--borders2);
    overflow: hidden;
    box-shadow: 3px 3px 0 rgba(50,50,50,.1)
}

.half, .small, .med, .large {
    margin: 0
}

.half {
    width: calc(50% - (var(--post-gap) / 4))
}

.small {
    width: calc(40% - (var(--post-gap) / 4))
}

.med {
    width: calc(60% - (var(--post-gap) / 4))
}

.large {
    width: 100%
}

/* Components */
.taskbar {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    width: 100vw;
    height: 50px;
    background: var(--posts);
    border-top: 1px solid var(--borders);
    box-shadow: inset 0 1px 0 var(--borders1);
    z-index: 9999999;
}

.tasks {
    margin: auto 0;
    width: 60%;
}

.tasks2 {
    display: inline-flex;
    margin: auto 0;
    max-width: 40%;
}

.btn,
[class^="btn_"] {
    display: inline-flex;
    margin: 0 1px 0 0;
    padding: 4px 10px;
    max-width: calc(20% - 1px);
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1), inset 0 0 1px 1px var(--borders);
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

/* Interactions */
.btn:hover,
[class^="btn_"]:hover,
.tasks2 a:hover {
    color: var(--text);
}

.btn:hover { /* later check again at line 2427 */
    outline: .75px dashed var(--text);
    outline-offset: -5px;
    cursor: pointer;
}

.btn.act,
[class^="btn_"].act {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders1);
    border-right: 1px solid var(--borders1);
}

/* Typography */
.linfo {
    margin: 0;
    width: 100%;
}

.ltitle {
    width: 100%;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn .ti,
[class^="btn_"] .ti,
.clock-wrap .ti {
    margin: auto 5px auto 0;
    font-size: var(--body-size);
    color: var(--text);
}

.clock-wrap {
    margin: auto 0 auto 1px;
    padding: 4px 10px;
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-right: 1px solid var(--borders1);
    border-bottom: 1px solid var(--borders1);
    box-shadow: inset 0 1px 0 var(--borders1);
}

#clock {
    display: inline-flex;
}

.btn img,[class^="btn_"] img,.clock-wrap img {
    margin: auto 5px auto 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

body.dark [class^="btn_"].act, body.dark .btn.act, body.dark .tasks2 a:active { /* later check again at line 2509 */
    background: rgba(255,255,255,.12); 
}

/* ---------- IMAGE WINDOW ---------- */
.image-wrap {
    left: 400px;
    bottom: 60px;
    width: 13vw
}

/* ---------- START WINDOW ---------- */
.start-wrap {
    left: 20px;
    bottom: 80px;
    width: 20vw
}

.start-wrap .pref {
    padding: 0 10px 5px
}

.pref span, .pref a {
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 4px 7px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
    word-break: keep-all
}

.cover {
    width: 100%;
    max-height: 18vh;
    background: radial-gradient(ellipse at top,var(--accent1),transparent),radial-gradient(ellipse at bottom,var(--accent2),transparent);
    overflow: hidden;
    border-top: 1px solid var(--borders2);
    box-shadow: inset 0 1px 0 var(--borders1)
}

.cover img {
    width: 100%;
    max-height: 18vh;
    object-fit: fill
}

.por {
    margin: -40px auto 10px
}

.por img {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    padding: 3px;
    background: var(--posts);
    border: 1px solid var(--borders2);
    box-shadow: inset 1px 1px 0 var(--borders1)
}

.por.circle img {
    border-radius: 100%
}

.por.square img {
    border-radius: 0
}

.desc {
    margin: 10px auto 0;
    max-height: 20vh;
    overflow-y: auto;
    cursor: auto
}

/* ---------- SEARCH WINDOW ---------- */
.start-wrap, .up-wrap, .search-wrap, .image-wrap {
    position: fixed;
    z-index: 9999;
    cursor: move;
}

.up-wrap, .search-wrap {
    right: 20px;
    width: 20vw;
}

.up-wrap {
    bottom: 400px;
}

.search-wrap {
    bottom: 80px;
}

.start-wrap article, .up-wrap article, .search-wrap article, .image-wrap article {
    margin: 0;
}

.start-wrap .inner-padding, .up-wrap .up, .search-wrap .inner-padding, .image-wrap .inner-padding {
    cursor: auto;
}

.searchbar {
    margin: 0;
    width: 100%;
    background: transparent;
    color: var(--text);
    font-size: var(--body-size);
    font-family: var(--body-font);
    outline: 0!important;
    border-width: 0!important;
}

/* ---------- MAIN WINDOW ---------- */
.package {
    margin: calc(6vh - (40px /2)) auto;
    padding: 0;
    width: calc(var(--side-size) + var(--post-size) + 134px);
    background: var(--posts);
    border: 1px solid var(--borders1);
    outline: 1px solid var(--borders2);
    box-shadow: 3px 3px 0 rgba(50,50,50,.1);
    overflow: hidden;
    /* z-index: 1; */
}

.packhead {
    width: 100%;
}

.post-header {
    margin: 0;
    padding: 10px;
    background: var(--accent-text);
    background-image: linear-gradient(to right,var(--accent1),var(--accent2));
    color: var(--text);
    width: 100%;
    cursor: move; /* nurul add 20240216*/
}

.winhead {
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
}

.winhead img {
    margin: auto 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.w-info {
    margin: 0;
    width: calc(100% - 30px);
}

.w-title {
    vertical-align: middle;
    margin: auto 0;
    width: 90%; /* 100%; */
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
}

.winhead .ti { /* icons */
    margin: auto 0;
    vertical-align: middle;
    padding: 3px;
    font-size: 14px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
}

.post-btns {
    margin: 0;
}

.post-btns .ti {
    margin: auto 3px;
    vertical-align: middle;
    padding: 3px;
    font-size: 14px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
}

.post-btns .ti.win {
    margin: auto -4px;
}

.post-btns .ti.ex:hover,.post-btns .ti.close:hover {
    cursor: pointer;
}

.post-btns .ti.ex:active,.post-btns .ti.close:active {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders1);
    border-right: 1px solid var(--borders1);
}

.address-bar {
    margin: 0;
    padding: 10px;
    width: 100%;
    background: var(--posts);
    border-top: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders2);
    box-shadow: 0 1px 0 var(--borders1),inset 0 1px 0 var(--borders1)
}

.address-bar .ti {
    display: inline-flex;
    margin: auto 0;
    vertical-align: middle;
    padding: 6px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
    font-size: calc(var(--body-size) + 2px)
}

.address-bar a,
.address-bar label,
.to-top,
.m-to-top {
    margin: auto 0
}

.address {
    margin: auto 0;
    padding: 4px 10px;
    width: calc(100% - 140px);
    background: var(--captions);
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-right: 1px solid var(--borders);
    border-bottom: 1px solid var(--borders);
    white-space: nowrap;
    overflow: hidden;
    line-height: var(--line-hgt)
}

.a-info {
    margin: 0;
    width: 100%
}

.a-name {
    vertical-align: middle;
    margin: auto 0;
    width: 100%;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.urlol {
    color: rgba(var(--rgb-text),.5)
}

.home-wrap {
    display: inline-block;
}

.page-1 {
    display: none;
}

.to-top .ti, .m-to-top .ti {
    color: rgba(var(--rgb-text),.5)!important;
}

.to-top .ti:hover,.m-to-top .ti {
    color: rgba(var(--rgb-text),.5)!important;
    cursor: default;
}

.scroll, .m-scroll {
    display: none;
    position: absolute;
    margin: 0;
}

.scroll .ti, .m-scroll .ti {
    margin: 0;
    color: var(--text)!important;
    z-index: 9999;
    animation: fadein 0.4s ease-in-out forwards;
}

.scroll .ti:hover,.m-scroll .ti:hover {
    color: var(--text)!important;
    cursor: pointer!important;
}

.tbtn .close.act .ti {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders1);
    border-right: 1px solid var(--borders1);
}

/* ---------- SIDEBAR ---------- */
.sidebar {
    position: sticky;
    position: -webkit-sticky;
    margin: 0;
    padding: 10px;
    width: var(--side-size)
}

.sidebox {
    margin: auto 0;
    width: 100%;
    height: calc(75vh - 40px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.inner-padding, 
.sidebox,
.desc {
    padding: 10px;
    background: var(--captions);
    border: 1px solid;
    border-color: var(--borders2) var(--borders) var(--borders) var(--borders2);
}

.sidebox::-webkit-scrollbar,.desc::-webkit-scrollbar,.up::-webkit-scrollbar {
    width: 14px;
}

.sidebox::-webkit-scrollbar-thumb,.desc::-webkit-scrollbar-thumb,.up::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
}

.folders {
    margin: 0;
}

.folders img {
    margin: auto 7px auto 2px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    z-index: 99;
}

.inner-folder {
    padding-left: 13px;
    width: 100%;
    border-left: 1px dotted var(--sideborders);
}

.inner-folder a,.inner-folder label {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fold {
    position: relative;
    margin-bottom: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.fold:last-of-type {
    margin-bottom: 0;
}

.folds a:last-of-type, .folds label:last-of-type {
    margin-top: 1px;
    border-width: 0
}

.folds a:last-of-type:before, .folds label:last-of-type:before {
    content: "";
    position: absolute;
    top: calc((var(--line-hgt) / 2) + 2px);
    left: 0;
    width: 5px;
    height: 1px;
    border-bottom: 1px dotted var(--sideborders)
}

.folds a:last-of-type:after {
    content: "";
    position: absolute;
    bottom: calc((var(--line-hgt) / 2) + 7px);
    left: 0;
    width: 10px;
    height: calc(100% - 16px);
    border-left: 1px dotted var(--sideborders)
}

.folds label:last-of-type:after {
    content: "";
    position: absolute;
    bottom: calc((var(--line-hgt) / 2) + 7px);
    left: 0;
    width: 10px;
    height: calc(100% - 1px);
    border-left: 1px dotted var(--sideborders)
}

.fold-info {
    margin: 0;
    width: calc(100% - 28px);
}

.fold-name {
    vertical-align: middle;
    margin: auto 0;
    width: 100%;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fold-name:hover {
    color: var(--links-hover);
}

.folds {
    margin-left: 8px;
}

.folds a, .folds label, .folds span {
    margin-top: 1px;
    display: block;
    position: relative;
    padding: 5px;
    color: var(--text);
    border-left: 1px dotted var(--sideborders);
}

.folds a:before,.folds label:before,.folds span:before {
    content: "";
    position: absolute;
    top: calc((var(--line-hgt) / 2) + 2px);
    left: 0;
    width: 5px;
    height: 1px;
    border-bottom: 1px dotted var(--sideborders);
}

.folds a:hover,.folds label:hover,.inner-folder a:hover {
    color: var(--links-hover);
    cursor: pointer;
}

#button_a:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_a,
#button_b:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_b,
#button_c:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_c,
#button_d:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_d,
#button_e:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_e,
#button_f:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_f,
#button_g:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_g,
#button_a:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_a:hover,
#button_b:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_b:hover,
#button_c:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_c:hover,
#button_d:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_d:hover,
#button_e:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_e:hover,
#button_f:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_f:hover,
#button_g:checked~.package>.sidebar>.sidebox>.folders>.folds>label.folder_g:hover {
    cursor: default;
    background: var(--accent1);
    color: var(--accent-text);
}

.folds img, .inner-folder img {
    margin-left: 2px
}

/* ---------- CONTENT ---------- */
.wrapper {
    margin: 0;
    padding: 10px 10px 10px 0;
    width: calc(var(--post-size) + 130px);
    max-width: 85vw;
}

.tabs {
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    background: var(--captions);
    height: calc(75vh - 40px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-right: 1px solid var(--borders);
    border-bottom: 1px solid var(--borders);
}

[class^="tab_"] {
    display: none;
    position: relative;
    margin: 0 auto;
    width: var(--post-size);
    animation: fadein 0.4s ease-in-out forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.tabs::-webkit-scrollbar,.sidebox::-webkit-scrollbar,.desc::-webkit-scrollbar,.up::-webkit-scrollbar {
    background-color: transparent;
}

.tabs::-webkit-scrollbar {
    width: 20px;
}

.tabs::-webkit-scrollbar-thumb,.sidebox::-webkit-scrollbar-thumb,.desc::-webkit-scrollbar-thumb,.up::-webkit-scrollbar-thumb {
    background: var(--accent1);
    background: linear-gradient(var(--accent1),var(--accent2));
    height: 90px;
    border: 6px solid transparent;
    background-clip: padding-box;
}

.tabs::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
}

/* ---------- ABOUT ---------- */
.about {
    margin: 0;
    width: 100%
}

#button_a:checked~.package>.wrapper>.tabs>.tab_a,
#button_b:checked~.package>.wrapper>.tabs>.tab_b,
#button_c:checked~.package>.wrapper>.tabs>.tab_c,
#button_d:checked~.package>.wrapper>.tabs>.tab_d,
#button_e:checked~.package>.wrapper>.tabs>.tab_e,
#button_f:checked~.package>.wrapper>.tabs>.tab_f,
#button_g:checked~.package>.wrapper>.tabs>.tab_g {
    display: block;
}

#button_a:checked~.taskbar>.tasks>.btn_a:hover,
#button_b:checked~.taskbar>.tasks>.btn_b:hover,
#button_c:checked~.taskbar>.tasks>.btn_c:hover,
#button_d:checked~.taskbar>.tasks>.btn_d:hover,
#button_e:checked~.taskbar>.tasks>.btn_e:hover,
#button_f:checked~.taskbar>.tasks>.btn_f:hover,
#button_g:checked~.taskbar>.tasks>.btn_g:hover {
    outline: 0 solid transparent;
}

/* ---------- POST ---------- */
.bar {
    margin: 0;
    padding: 8px 5px;
    width: 100%;
    border-top: 1px solid var(--borders2);
    box-shadow: inset 0 1px 0 var(--borders1)
}

.bar p,.bar a {
    display: inline-block;
    margin: 0 2px 0 0;
    padding: 2px 5px;
    color: var(--text);
    border: 1px solid var(--posts)
}

.bar p::first-letter,.bar a::first-letter {
    text-decoration: underline
}

.bar a:hover {
    border-right: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders2);
    cursor: pointer
}

.bar a:active,[class^="btn-"].act {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders1);
    border-right: 1px solid var(--borders1)
}

.t-wrap {
    position: relative;
    margin: 0;
    padding: 0 10px 10px;
    width: 100%
}

.tags {
    width: 100%
}

.ptags a,.tags a {
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 4px 7px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
    word-break: keep-all
}

.tags a {
    margin: 0 5px 5px 0
}

.ptags a:hover,.tags a:hover {
    color: var(--text)
}

/* ---------- SKILL ---------- */
/* .skills table {
    width: 100%;
}

.skills td {
    width: 80%;
}

.skills td:first-of-type {
    width: 20%;
    text-align: center;
}

.skills h4 {
    margin: 7px 0;
    vertical-align: middle;
}

.hp {
    width: 100%;
    height: 10px;
    background: var(--posts);
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-right: 1px solid var(--borders1);
    border-bottom: 1px solid var(--borders1);
}

.hpbar {
    margin: auto 0;
    vertical-align: middle;
    background-color: var(--accent1);
    background-image: linear-gradient(to right,var(--accent1),var(--accent2));
    height: 10px;
    background-clip: padding-box;
    color: var(--accent-text);
}

.hpbar.one {
    width: var(--skill1);
}

.hpbar.two {
    width: var(--skill2);
}

.hpbar.three {
    width: var(--skill3);
}

.hpbar.four {
    width: var(--skill4);
}

.hpbar.five {
    width: var(--skill5);
} */

.follows {
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.person {
    margin: 2px;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    background: var(--posts);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
}

.person img {
    background: var(--captions);
}

.person img {
    margin: 0 8px 0 0;
    vertical-align: middle;
    width: 43px !important; 
    height: 43px;
    background: var(--posts);
    /* border: 1px solid var(--borders); */
}

.f-info {
    margin: 0;
    width: calc(100% - 55px);
}

.f-title {
    font-weight: 700;
}

.f-name {
    color: var(--text);
}

.f-name, .f-title {
    vertical-align: middle;
    margin: auto 0;
    width: 100%;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.person:hover {
    outline: .75px dashed var(--text);
    outline-offset: -4px;
    cursor: pointer;
}

/* ---------- PORTFOLIO ---------- */
.explore {
    width: 100%;
}

.inner-padding img {
    width: 100%;
    object-fit: cover;
}

[class^="project-info"] {
    width: calc(33% - 1.5px);
}

.post-footer {
    padding: 0 10px 10px;
    color: var(--text);
    width: 100%;
    overflow: hidden;
}

.post-footer a {
    color: var(--text);
}

.post-footer a:hover {
    color: var(--links-hover);
}

.post-foot {
    margin: 0;
    width: 100%;
}

[class^="post-info"],
.controls {
    margin: 0;
}

[class^="project-info"],
[class^="post-info"],
.time-info {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-right: 1px solid var(--borders1);
    border-bottom: 1px solid var(--borders1);
    white-space: nowrap;
    overflow: hidden;
}

[class^="post-info"] {
    width: calc(38% - 1.5px);
}

.time-info {
    width: calc(24% - 1.5px);
    border-radius: 0
}

.pinfo {
    margin: 0;
    width: 100%;
}

.pnotes {
    vertical-align: middle;
    margin: auto 0;
    padding: 5px 10px;
    width: 100%;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center; /* nurul add 20240216 */
}

/* ---------- FOLDER 1 ---------- */
.error,
.no-posts {
    margin: 0 auto;
    width: var(--post-size);
}

.go-back {
    margin: 0 auto;
    width: 100%;
}

.yes, .no {
    width: calc(50% - 1px);
    color: var(--text);
    justify-content: center;
    text-align: center;
    background: var(--posts);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
    overflow: hidden;
}

.go-back a {
    padding: 5px 10px;
    justify-content: center;
}

.yes:hover,.no:hover {
    color: var(--text);
}

.no:hover {
    cursor: default;
}

a:active.yes {
    box-shadow: inset 1px 1px 0 0 var(--borders2),inset 0 0 1px 1px var(--borders1);
}

.yes:hover { /* later check again at line 2472 */
    outline: .75px dashed var(--text);
    outline-offset: -5px;
    cursor: pointer;
}

/* ---------- /// ---------- */
.content1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 5px; /* Adjust gap between items as needed */
}

.content1 span {
    padding: 4px 0;
}

.content1 .radio {
    width: 100%;
    padding: 4px 10px;
    background: var(--posts);
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1), inset 0 0 1px 1px var(--borders);
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
}

.radio .act {
    border-top: 1px solid var(--borders2);
    border-left: 1px solid var(--borders2);
    border-bottom: 1px solid var(--borders1);
    border-right: 1px solid var(--borders1);
}

[data-theme="daynight"] body.light .radio.act,
[data-theme="daynight"] body.light .btn.act {
    background: rgba(255, 255, 255, .12);
}

body.light .btn.act {
    background: rgba(0,0,0,.1);
}

.radio.act {
    background: rgba(0, 0, 0, .1);
}

/* ---------- /// ---------- */
.mobile-head,.m-to-top,.m-bar,[class^="btn_"],.arc,.m-cred,.bg-up,.bg-sea,.post-btns .ti.close,.dc {
    display: none;
}

.tags a:hover,
.ptags a:hover,
.address-bar .ti:hover,
.pref a:hover,
.explr a:hover,
.post-btns .ti.ex:hover,
.post-btns .ti.close:hover {
    outline: .75px dashed var(--text);
    outline-offset: -4px;
    cursor: pointer;
}

.to-top .ti:hover {
    outline: 0 dashed var(--text);
    outline-offset: 0;
    cursor: default;
}

.scroll .ti:hover {
    outline: .75px dashed var(--text);
    outline-offset: -4px;
    cursor: pointer
}