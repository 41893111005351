.header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    margin: 0;
    padding: 20px;
    background-color: var(--head);
    background-image: linear-gradient(to right, var(--accent1), var(--accent2));
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid var(--borders2);
    box-shadow: inset 0 1px 0 var(--borders1);
    z-index: 99999;
}

nav, .return {
    margin: 0;
}

nav a, .return a {
    background: var(--posts);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    padding: 10px 15px;
    color: var(--text);
    border: 1px solid var(--borders);
    box-shadow: inset 1px 1px 0 var(--borders1);
}

.return .ti {
    margin-right: 15px;
}

#page .ti {
    font-size: calc(var(--body-size) + 2px);
    vertical-align: middle;
}

#page .ti {
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#page .post-header {
    cursor: default;
}

#page .container {
    margin: 60px auto;
    width: 70vw;
}

#page h1 {
    font-size: 36px;
    text-align: center;
}

#page .content {
    margin: 60px auto;
    width: 100%;
    background: var(--posts);
    border: 1px solid var(--borders1);
    outline: 1px solid var(--borders2);
    box-shadow: 3px 3px 0 rgba(50,50,50,0.1);
}

#page .padding {
    padding: 10px;
    background: var(--posts);
    border-top: 1px solid var(--borders2);
    box-shadow: inset 0 1px 0 var(--borders1);
}